import React from "react";
// import Thumbnail from '../Thumbnail.js';
import '../App.css';
 
function Work(props) {
  return (
    <div>
      <h1>Work</h1>
      {/* <Thumbnail 
        link=""
        image=""
        title=""
        category=""
      /> */}
    </div>
  )
}
 
export default Work;